<template>
  <div class="w80-crpwd mt-5 mb-5">
    <div class="row text-center">
      <div class="col-lg-5 col-md-5 col-sm-1 d-none d-md-block">
        <h2 class="font-white text-center mt-10 mb-5 metaDashboard">Tabla de posiciones</h2>
        <img class="RB20-Dsh" src="../../../assets/RB20.png" alt="RB20">

        <div class="tabla-individual">
          <h4 class="font-white">Puntuación por carrera</h4>
          <table>
            <tr>
              <th class="font-white">Carrera</th>
              <th class="font-white">Puntos</th>
            </tr>
            <tr class="font-white bold" v-for="(value, key) in resultadosCarrera" :key="key">
              <td class="puntosPorCarrera" >{{ key }}</td>
              <td class="puntosPorCarrera" >{{ value.totalPuntos }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <div class="contenedor-form-dashboard">
          <img class="headregister2 d-sm-none" src="../../../assets/headDashboard.png" alt="Mobile Logo">
          <!-- <h1 class="font-white f50 mt-5">Dashboard</h1> -->

          <div class="row mt-1 w100-dashboard">
            <h2 class="font-white text-center mt-5">Aquí puedes revisar la tabla de posiciones de Mobil Predictor y
              descubre si estás en la pole position.</h2>
            <div class="col-4">
              <img class="checoDashboard" src="../../../assets/ChecoDashboard.png" alt="">
            </div>
            <div class="col-8 tabla">
              <h1 class="font-white text-center mt-5 mb-5 f30">Mis Resultados</h1>

              <div v-for="(data, index) in filteredData" :key="index" class="row dashboard-score">
                <div class="col-4">
                  <h3 class="font-white text-score">Mi posición</h3>
                  <p class="scoreboard">{{ data.ranking }}</p>
                </div>
                <div class="col-5">
                  <h3 class="font-white text-score">Usuario</h3>
                  <p class="scoreboard">{{ data.name }}</p>
                </div>
                <div class="col-3">
                  <h3 class="font-white text-score">Puntos</h3>
                  <p class="scoreboard">{{ data.score }}</p>
                </div>
              </div>
            </div>
          </div>

          <h2 class="font-white text-center mt-5 mb-10 f30">Top 10</h2>
          <div class="row dashboard-table-content">
            <div class="col-4 p-none">
              <p class="font-white">Predicciones</p>
              <!-- Posición -->
            </div>
            <div class="col-4 p-none">
              <p class="font-white">Usuario</p>
            </div>
            <div class="col-4 p-none">
              <p class="font-white">Puntos</p>
            </div>
          </div>
          <div v-for="(data, index) in rankingData" :key="index" class="row dashboard-table-content">
            <div class="col-4 p-none">
              <p class="font-white score-boards ">{{ data.ranking }}</p>
            </div>
            <div class="col-4 p-none">
              <p class="font-white score-boards ">{{ data.name + ' ' + data.first_name.charAt(0) + '.' }}</p>
            </div>
            <div class="col-4 p-none">
              <p class="font-white score-boards ">{{ data.score }}</p>
            </div>
          </div>

          <div class="mt-10 mb-10">
            <button class="btn btn-primary btn-lg btn-launch-crpwd font-white"
              @click="redirectWithTimeout('/predicciones-pilotos-checo')">Participar</button>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-1 d-none d-md-block">
        <img class="m50ydashboard" src="../../../assets/Mobil50Y.png" alt="">
      </div>
    </div>
  </div>
</template>

<script src="./dashboard.js" />
<style src="./dashboard.scss" lang="scss" />
<script>
import axios from "axios";

export default {
  name: 'dashboard',
  metaInfo: {
    title: 'Tabla de posiciones | Mobil Predictor',
    meta: [
      { name: 'title', content: 'Tabla de posiciones | Mobil Predictor' },
      { name: 'description', content: '¿Bienvenido de nuevo a Mobil Predictor. Acelera hacia la emoción e inicia sesión en tu cuenta para disfrutar de la experiencia de predicción más emocionante' }
    ]
  },
  data() {
    return {
      rankingData: [],
      filteredData: [],
      resultadosCarrera: {}
    };
  },
  mounted() {
    this.fetchData();
    if (!localStorage.getItem('predictor')) {
      this.$router.push('/iniciar-sesion');
    }
    this.puntosPorCarrera();
  },
  methods: {
    fetchData() {
      axios
        .get('https://www.mobilpredictor.com.mx/datos/api/ranking')
        .then((response) => {
          // Solo los primeros 10
          this.rankingData = response.data.filter(data => data.ranking !== null).sort((a, b) => a.ranking - b.ranking).slice(0, 10);

          const predictor = JSON.parse(localStorage.getItem('predictor'));
          const user = predictor.email;

          this.filteredData = response.data.filter(data => data.email === user);
          // console.log('Filtrado', this.filteredData);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    puntosPorCarrera() {
      const predictor = JSON.parse(localStorage.getItem('predictor'));
      const email = predictor.email;

      axios.post('https://www.mobilpredictor.com.mx/datos/api/puntuacionesCarrera', { email })
        .then((response) => {
          this.resultadosCarrera = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    redirectWithTimeout(address) {
      setTimeout(() => {
        window.location.href = address;
      }, 1500);
    }
  },
};
</script>
