<template>
  <v-footer padless>
    <v-row no-gutters>
      <!-- <v-col cols="12" class="firstRow d-flex justify-center">
        <img :src="require('../../assets/logo1_1v.png')" >
      </v-col> -->
      <!-- Para desktop -->
      <v-col class="firstRow py-2" cols="11" v-if="$vuetify.breakpoint.mdAndUp">
        <ul class="mt-2 links-footer">
          <li>
            <a class="font-white link-footer" target="_blank" href="/preguntas-frecuentes">Preguntas frecuentes
            </a>
          </li>
          <li>
            <a class="font-white link-footer" target="_blank" href="https://www.facebook.com/MobilMexico/">Contacto</a>
          </li>
          <li>
            <a class="font-white link-footer" target="_blank" href="/terminos-y-condiciones">Términos y Condiciones
            </a>
          </li>
          <li>
            <a class="font-white link-footer" target="_blank"
              href="https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing">Aviso de privacidad</a>
          </li>
        </ul>
      </v-col>
      <v-col class="firstRow py-3" cols="1" v-if="$vuetify.breakpoint.mdAndUp">
        <ul class="mt-2 pe-10">
          <li>
            <a class="hover-target" target="_blank" href="https://www.instagram.com/mobilmexico/"><i
                class="fab fa-instagram ml-3"></i></a>
          </li>
          <li>
            <a class="hover-target" target="_blank" href="https://www.facebook.com/MobilMexico"><i
                class="fab fa-facebook-f ml-4"></i></a>
          </li>
          <li>
            <a class="hover-target" target="_blank" href="https://www.linkedin.com/showcase/mobil-mexico/"><i
                class="fab fa-linkedin-in ml-3"></i></a>
          </li>
        </ul>
      </v-col>
      <!-- Para dispositivos moviles -->
      <v-col class="firstRow pb-3" cols="12" v-if="$vuetify.breakpoint.smAndDown">
        <ul class="mt-2 links-footer">
          <li>
            <a class="font-white link-footer" target="_blank" href="/preguntas-frecuentes">Preguntas frecuentes
            </a>
          </li>
          <li>
            <a class="font-white link-footer" target="_blank" href="https://www.facebook.com/MobilMexico/">Contacto</a>
          </li>
          <li>
            <a class="font-white link-footer" target="_blank" href="/terminos-y-condiciones">Términos y Condiciones
            </a>
          </li>
          <li>
            <a class="font-white link-footer" target="_blank"
              href="https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing">Aviso de privacidad</a>
          </li>
        </ul>
      </v-col>
      <v-col class="firstRow pb-3" cols="12" v-if="$vuetify.breakpoint.smAndDown">
        <ul class="mt-2 ps-10">
          <li>
            <a class="hover-target" target="_blank" href="https://www.instagram.com/mobilmexico/">
              <i class="fab fa-instagram ml-3"></i>
            </a>
          </li>
          <li>
            <a class="hover-target" target="_blank" href="https://www.facebook.com/MobilMexico">
              <i class="fab fa-facebook-f ml-4"></i>
            </a>
          </li>
          <li>
            <a class="hover-target" target="_blank" href="https://www.linkedin.com/showcase/mobil-mexico/">
              <i class="fab fa-linkedin-in ml-3"></i>
            </a>
          </li>
          <!-- <li>
            <img class="icon50Years" src="../../assets/MobilIconFooter.png" alt="Mobil50Years"
              v-if="$vuetify.breakpoint.mdAndDown">
          </li> -->
        </ul>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="secondRow">
        <p class="textFooter">
          © Copyright 2024 Exxon Mobil Corporation. All Rights Reserved.
        </p>
        <!-- <hr> -->
        <!-- <p class="textFooter">
          <a class="termsconds" target="_blank" href="/checo_data/avisopriv.pdf">Aviso de privacidad</a>  | <a class="termsconds" href="/terminosycondiciones">Términos y condiciones</a> </p> -->
      </v-col>
    </v-row>
  </v-footer>
</template>
<script src="./mobileFooter.js" />
<script src="./mobileFooter.scss" />
<script>
export default {
  name: "mobileFooter",
};
</script>
<style lang="scss" scoped>
.termsconds {
  color: #868c96;
  text-decoration: none;
}

a,
i {
  color: #fff;
}

.secondRow {
  background-color: #282828;
}

.firstRow {
  background-color: #363636;
}

.textFooter {
  font-family: EMprint;
  font-size: 11px;
  text-align: center;
  color: #868c96 !important;
  margin-top: 16px;
}

img {
  max-width: 100px;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto auto;
  align-content: center;
  justify-content: center;
}

li {
  font-size: 25px;
  margin: 0 10px;
}

.row {
  width: 100% !important;
}

hr {
  opacity: 0.1;
}

.links-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  li {
    margin-right: 0px;
    margin-left: 200px;
  }
}

a.font-white.link-footer {
  /* font-family: EMprint; */
  font-size: 18px;
  font-weight: 700;
  line-height: 29.45px;
  text-align: center;
}

@media only screen and (min-width: 1440px) and (max-width: 1660px) {
  ul {
    width: 70%;
  }

  li {
    font-size: 20px;
  }

  .links-footer li {
    margin-right: 0px;
    margin-left: 100px;
  }

  a.font-white.link-footer {
  /* font-family: EMprint; */
  font-size: 16px;
  line-height: 20px
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1440px) {
  ul {
    width: 90%;
  }

  li {
    font-size: 20px;
  }

  a.font-white.link-footer {
  /* font-family: EMprint; */
  font-size: 16px;
  line-height: 20px
  }

  .links-footer li {
    margin-right: 0px;
    margin-left: 100px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  a.font-white.link-footer {
    font-size: 18px;
  }

  a.hover-target {
    font-size: 20px;
  }

  .links-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      margin-right: 0px;
      margin-left: 50px;
    }
  }

  ul.mt-2.pe-10 {
    display: flex;
    flex-direction: row;
    justify-content: end;

    li {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
}

@media only screen and (min-width: 960px) and (max-width: 1024px) {
  a.font-white.link-footer {
    font-size: 16px;
  }

  a.hover-target {
    font-size: 20px;
  }

  .links-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      margin-right: 0px;
      margin-left: 50px;
    }
  }

  ul.mt-2.pe-10 {
    display: flex;
    flex-direction: row;
    justify-content: end;

    li {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 960px) {
  ul {
        padding-left: 0px;
        padding-right: 90px;
    }

  a.font-white.link-footer {
    font-size: 20px;
    line-height: 15px;
  }

  a.hover-target {
    font-size: 20px;
  }

  li {
    margin-top: 10px;
    font-size: 18px;
  }

  .links-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      margin-right: 0px;
      margin-left: 100px;
    }
  }

  ul.mt-2.pe-10 {
    display: flex;
    flex-direction: row;
    justify-content: end;

    li {
      margin-right: 0px;
      margin-left: 10px;
    }
  }

}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  ul {
        padding-left: 0px;
        padding-right: 90px;
    }

  a.font-white.link-footer {
    font-size: 14px;
  }

  a.hover-target {
    font-size: 20px;
  }

  li {
    font-size: 15px;
  }

  .links-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      margin-right: 0px;
      margin-left: 100px;
    }
  }

  ul.mt-2.pe-10 {
    display: flex;
    flex-direction: row;
    justify-content: end;

    li {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
}

@media (max-width: 600px) {
  .links-footer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;

    li {
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  a.font-white.link-footer {
    /* font-family: EMprint; */
    font-size: 18px;
    font-weight: 700;
    line-height: 29.45px;
    text-align: center;
  }
}
</style>
