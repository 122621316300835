<template>
  <div class="w80-Upwd mt-5 mb-5">
    <div class="row text-center">
      <div class="col-lg-4 col-md-3 col-sm-2 d-none d-md-block d-sm-none">
        <img class="MaxV" src="../../../assets/Max.png" alt="MaxV" />
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8 col-xs-12">
        <div class="contenedor-form mt-20">
          <img
            class="headregister d-sm-none"
            src="../../../assets/registerHead.png"
            alt="Mobile Logo"
          />
          <!-- <h1 class="font-white f50 mt-5">Mobil Predictor</h1> -->
          <h2 class="font-white text-center mt-5 mb-5 f30">
            Cambiar nueva contraseña
          </h2>
          <form
            action=""
            class="mt-5 w100"
            @submit.prevent="submitUpdatePassword"
          >
            <div class="form-group mb-5">
              <input
                :type="showPassword1 ? 'text' : 'password'"
                class="form-control input24"
                id="new_password"
                v-model="form.newPassword"
                placeholder="Contraseña*"
              />
              <i class="fa fa-eye password-icon-cc2" @click="showPassword1 = !showPassword1"></i>
            </div>
            <div class="form-group mb-5">
              <input
                :type="showPassword2 ? 'text' : 'password'"
                class="form-control input24"
                id="confirm_password"
                v-model="form.confirmPassword"
                placeholder="Confirmar contraseña*"
              />
              <i class="fa fa-eye password-icon-cc2" @click="showPassword2 = !showPassword2"></i>
            </div>
            <div class="d-flex justify-center mt-10">
              <button class="btn btn-primary btn-lg btn-launch-crpwd">
                Actualizar
              </button>
            </div>
          </form>
          <!-- <a class="font-white mt-5" href="">Olvidaste tu contraseña</a> -->
        </div>
      </div>
      <div class="col-lg-4 col-md-3 col-sm-4 d-none d-sm-block">
        <img class="Checo" src="../../../assets/Checo.png" alt="Checo" />
      </div>
    </div>
  </div>
</template>

<script src="./actualizarPassword.js" />
<style src="./actualizarPassword.scss" lang="scss" />

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';

  export default {
    name: 'actualizarPassword',
    metaInfo: {
    title: 'Cambia tu contraseña | Mobil Predictor',
    meta: [
        { name: 'title', content: 'Cambia tu contraseña | Mobil Predictor' },
        { name: 'description', content: '¿Necesitas cambiar tu contraseña? No te preocupes. ¡Entra aquí y vuelve a jugar en Mobil Predictor!' }
      ]
    },
    data() {
      return {
        form: {
          email: this.$route.params.correo,
          newPassword: '',
          confirmPassword: '',
        },
        showPassword1: false,
        showPassword2: false
      };
    },
    methods: {
      async submitUpdatePassword() {
      try {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await axios.post('https://www.mobilpredictor.com.mx/datos/api/password/update', this.form);

        if (response.data.message == 'EmailNoExist') {
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'No se pudo actualizar la contraseña, intenta de nuevo',
              background: "#000",
              color: "#fff",
              customClass: {
                content: 'sweetAlertFont'
              },
            });
        }

        if (response.data.message == 'Success') {
          Swal.fire({
              icon: 'success',
              title: 'Contraseña actualizada',
              text: 'Tu contraseña ha sido actualizada correctamente',
              background: "#000",
              color: "#fff",
              customClass: {
                content: 'sweetAlertFont'
              },
            }).then(() => {
              this.$router.push({ name: 'login' });
            });

        }

        if (response.data.message == 'PasswordNotMatch') {
          Swal.fire({
              icon: 'warning',
              title: 'Error',
              text: 'Las contraseñas no coinciden',
              background: "#000",
              color: "#fff",
              customClass: {
                content: 'sweetAlertFont'
              },
            });
        }

        if (response.data.message == 'pwdIsUsed') {
          Swal.fire({
              icon: 'info',
              title: 'Error',
              text: 'No puedes usar una contraseña que ya hayas utilizado anteriormente',
              background: "#000",
              color: "#fff",
              customClass: {
                content: 'sweetAlertFont'
              },
            });
        }

      } catch (error) {
        // console.error(error);
      }
    }
  }
};
</script>
