<template>
  <div class="w80-login">
    <div class="row text-center">
      <div class="col-lg-6 col-md-6 col-sm-8 col-xs-12">
        <div class="contenedor-form-login">
          <img class="headregister d-sm-none" src="../../../assets/HeaderLogin.png" alt="Mobile Logo" />
          <h1 class="font-white mt-15 tituloBC">
            Bienvenido de nuevo a Mobil Predictor
          </h1>
          <h2 class="font-white mt-5 mb-5 subtituloBC">
            Acelera hacia la emoción e inicia sesión en tu cuenta para disfrutar
            de la experiencia de predicción más emocionante de la Gran Carrera.
          </h2>

          <h2 class="font-white mb-5 subtituloBC text-center">
            Iniciar Sesión
          </h2>
          <form action="" class="mt-5 w100" @submit.prevent="submitFormLogin">
            <div class="form-group mb-5 BC">
              <input type="text" class="form-control input24" id="email" v-model="form.email"
                placeholder="Correo electrónico*" />
            </div>
            <div class="form-group mb-5 BC position-relative">
              <input :type="showPassword ? 'text' : 'password'" class="form-control input24" id="password"
                v-model="form.password" placeholder="Contraseña*" />
              <i class="fa fa-eye password-icon-login" @click="showPassword = !showPassword"></i>
            </div>
            <div class="d-flex justify-center mt-3">
              <button class="btn btn-primary btn-lg btn-launch-iniciarSesion">
                ¡Inicia sesión ahora mismo!
              </button>
            </div>
          </form>
          <div class="d-flex justify-center mt-3">
            <button class="font-white iniciarSesionLogin" @click="redirectWithTimeout('/olvide-contrasena')">Reestablece
              tu contraseña</button>
          </div>
          <div class="d-flex justify-center mt-3 mb-10">
            <a class="font-white notengocuenta" @click="redirectWithTimeout('/')">¿No tienes una cuenta?
              <br>Registrate aqui</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-4 col-xs-12">
        <img class="ChecoCasco" src="../../../assets/ChecoCasco.png" alt="Max" />
      </div>
    </div>
  </div>
</template>

<script src="./iniciarSesion.js" />
<style src="./iniciarSesion.scss" lang="scss" />

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: 'iniciarSesion',
  metaInfo: {
    title: 'Inicio de sesión - Mobil Predictor',
    meta: [
      { name: 'title', content: 'Inicio de sesión - Mobil Predictor' },
      { name: 'description', content: '¡la Gran Carrera está apunto de comenzar! Entra a Mobil Predictor y predice los resultados de Checo, Max y de Oracle Red Bull Racing.' }
    ]
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showPassword: false
    };
  },
  mounted() {
    fbq('track','Traffic');
    if (localStorage.getItem("predictor") && document.cookie.includes("predictor")) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    async submitFormLogin() {
      if (document.cookie.includes("login")) {

        axios.defaults.headers.common['Content-Type'] = 'application/json';
          const response = await axios.post(
            'https://www.mobilpredictor.com.mx/datos/api/loginReturn',
            this.form
          );
          // console.log(response.data);
          if (response.status === 201) {
            this.$router.push("/dashboard");
            localStorage.setItem("predictor", JSON.stringify(response.data));
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 30);
            document.cookie = `predictor=${JSON.stringify(
              response.data.email
            )}; expires=${expirationDate.toUTCString()}; path=/`;
          }

      }else{
        try {
          axios.defaults.headers.common['Content-Type'] = 'application/json';
          const response = await axios.post(
            'https://www.mobilpredictor.com.mx/datos/api/login',
            this.form
          );
          // console.log(response.data);
          if (response.status === 201) {
            this.$router.push("/doble-auth");
            localStorage.setItem("predictor", JSON.stringify(response.data));
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 30);
            document.cookie = `predictor=${JSON.stringify(
              response.data.email
            )}; expires=${expirationDate.toUTCString()}; path=/`;

            document.cookie = `login=${JSON.stringify(response.data.email)}; expires=${expirationDate.toUTCString()}; path=/`;
          }

        } catch (error) {
          // console.error(error);
          Swal.fire({
              title: "Datos inválidos",
              text: "El correo o la contraseña son incorrectos, por favor intenta de nuevo.",
              icon: "warning",
              background: "#000",
                color: "#fff",
                customClass: {
                  content: 'sweetAlertFont'
                },
            });
        }
      }
    },
    redirectWithTimeout(address) {
      setTimeout(() => {
        window.location.href = address;
      }, 1500);
    }
  },
};
</script>
