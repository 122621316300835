<template>
  <div class="w80-crpwd mt-5 mb-5">
    <div class="row text-center">
      <div class="col-lg-7 col-md-6 col-sm-12 d-none d-sm-block">
        <img
          class="checomaxmobil"
          src="../../../assets/ChecoMaxMobil.png"
          alt="ChecoMaxMobil"
        />
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12 col-xs-12">
        <div class="formResetPassword">
          <img
            class="headregister d-sm-none mb-10"
            src="../../../assets/registerHead.png"
            alt="Mobile Logo"
          />
          <h2 class="font-white text-center pfrecuentes">
            Buscar cuenta de correo
          </h2>
          <h6 class="font-white text-center mt-5 mb-5 f20 px-10">
            Te enviaremos un correo electrónico para recuperar tu cuenta. Ingresa el correo con el que te registraste aquí:
          </h6>
          <form
            action=""
            class="mt-5 w100"
            @submit.prevent="submitFormRegister"
          >
            <div class="form-group mb-5">
              <input
                type="text"
                class="form-control input24"
                id="email"
                v-model="form.email"
                placeholder="Correo*"
              />
            </div>
            <div class="d-flex justify-center mt-10">
              <button class="btn btn-primary btn-lg btn-launch-crpwd">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./resetPassword.js" />
<style src="./resetPassword.scss" lang="scss" />

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: 'resetPassword',
  metaInfo: {
    title: 'Olvidé mi contraseña - Mobil Predictor',
    meta: [
      { name: 'title', content: 'Olvidé mi contraseña - Mobil Predictor' },
      { name: 'description', content: '¿Olvidaste tu contraseña de Mobil Predcitor? Encuentra tu dirección y reestablece tu clave. Te enviaremos un correo para recuperar tu cuenta. Ingresa aquí.' }
    ]
  },
  data() {
    return {
      form: {
        email: "",
      },
    };
  },
  methods: {
    async submitFormRegister() {
      try {
        axios.defaults.headers.common["Content-Type"] = "application/json"; // Add this line
        const response = await axios.post(
          'https://www.mobilpredictor.com.mx/datos/api/password/reset',
          this.form
        );

        if (response.data.message == "Success") {
          Swal.fire({
            icon: "success",
            title: "Correo enviado",
            text: "Hemos enviado las instrucciones al correo que nos proporcionaste.",
            background: "#000",
                color: "#fff",
                customClass: {
                  content: 'sweetAlertFont'
                },
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 1500);
        }
      } catch (error) {
        // console.error(error);
        if (error.response.message == "AccountNotFound") {
          Swal.fire({
            icon: "warning",
            title: "Correo no registrado",
            text: "Por favor verifica tu correo e intenta nuevamente",
            background: "#000",
                color: "#fff",
                customClass: {
                  content: 'sweetAlertFont'
                },
          });
        }
      }
    },
  },
};
</script>
