<template>
  <div class="w80-crpwd mt-5 mb-5">
    <div class="row text-center">
      <div class="col-lg-6 col-md-5 col-sm-1 d-none d-md-block d-sm-none">
        <img class="ChecoMaxMobil px-10" src="../../../../assets/ChecoMaxMobil.png" alt="MaxV" />
      </div>
      <div class="col-lg-4 col-md-5 col-sm-10 col-xs-12">
        <div class="contenedor-form-login">
          <img class="headregister d-sm-none" src="../../../../assets/headPredicciones.png" alt="Mobile Logo" />
          <h1 class="font-white f40 mt-5">Gran premio de Singapur</h1>
          <h2 class="font-white text-left mt-5 mb-5 me-5 ms-5 tituloPredicciones d-sm-none">Es tu turno de pisar el
            acelerador.
            Añade tus avances y pronósticos
            para la próxima Gran Carrera.</h2>
          <div class="row w80-predicciones mt-5">
            <!-- <div class="col-6 tag-predicciones-content">
              <a href="/predicciones" class="tag-predicciones">Predicciones</a>
            </div> -->
            <div class="col-6 tag-predicciones-content">
              <button class="tag-predicciones" @click="alertaCambioPaginaPilotos">Pilotos</button>
            </div>
            <div class="col-6 tag-predicciones-content-mobil">
              <a href="/predicciones-mobil" class="tag-predicciones">Mobil</a>
            </div>
          </div>
          <div class="mt-10 w80-predicciones" >
            <!-- <p class="font-white f40 mt-5" v-if="!isFormVisible">Predicciones no disponibles, se abren el lunes antes de la carrera.</p> -->
            <p class="font-white f40 mt-5" v-if="!showQuestions">Predicciones no disponibles, se abren el lunes antes de la carrera.</p>
            <!-- <p class="font-white f40 mt-5" v-if="isFormVisible">Nota: En las preguntas con opciones, coloca la letra de la opción.</p> -->
            <!-- <form class="" action="" @submit.prevent="submitFormQuestionsMobil" v-if="isFormVisible"> -->
              <form class="" action="" @submit.prevent="submitFormQuestionsMobil" v-if="showQuestions">
              <!-- <div v-for="(question, index) in questions" :key="question.id"> -->
              <!-- Muestra la pregunta -->
              <!-- <h3 class="pregunta font-white mt-3"> -->
              <!-- {{ question.question }} -->
              <!-- </h3> -->
              <!-- <div class="d-flex justify-between mt-3"> -->
              <!-- Crea un campo de entrada para la respuesta del usuario.
                      Utiliza `v-model` para vincular la entrada del usuario a `form[index].answer` -->
              <!-- <input type="text" class="input-predicciones" placeholder="Respuesta..." :id="'respuesta' + question.id"
                    :name="'respuesta' + question.id" v-model="form[index].answer" /> -->
              <!-- Muestra el valor de los puntos de la pregunta -->
              <!-- <p class="font-white mt-2">
                    {{ question.points_value }} puntos
                  </p> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- PREGUNTAS MANUALES -->
              <!-- Pregunta 1 -->
                <h3 class="pregunta font-white mt-3">
                {{ form[0].pregunta1 }}
                </h3>
                <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[0].respuesta1">
                  <option v-for="option in form[0].options" :key="option.id" :value="option.id">{{ option.text }}</option>
                </select>
                <p class="font-white mt-2">50 Puntos</p>
                </div>
              <!-- Fin de pregunta 1 -->
              <!-- Pregunta 2 -->
                <h3 class="pregunta font-white mt-3">
                {{ form[1].pregunta2 }}
                </h3>
                <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[1].respuesta2">
                  <option v-for="option in form[1].options" :key="option.id" :value="option.id">{{ option.text }}</option>
                </select>
                <p class="font-white mt-2">50 Puntos</p>
                </div>
              <!-- Fin de pregunta 2 -->
              <div class="d-flex justify-center mt-10">
                <button class="btn btn-primary btn-lg btn-launch-predicciones">
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-1">
        <img class="m50ydashboard" src="../../../../assets/Mobil50Y.png" alt="Checo">
      </div>
    </div>
  </div>
</template>
<script src="./mobil.js" />
<style src="./mobil.scss" lang="scss" />
<style src="../predicciones/predicciones.scss" lang="scss" />

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: 'mobil',
  metaInfo: {
    title: 'Demuestra tus conocimientos sobre Mobil | Mobil Predictor',
    meta: [
      { name: 'title', content: 'Demuestra tus conocimientos sobre Mobil | Mobil Predictor' },
      { name: 'description', content: '¡Entra a Mobil Predictor para realizar tu predicción y demostrar tu conocimiento sobre nuestra marca y pilotos! Llena los campos y gana puntos' }
    ]
  },
  data() {
    return {
      questions: [],
      form: [
        {
          pregunta1: "¿Qué combustible Mobil cargas con mayor frecuencia?",
          respuesta1: "",
          options: [
            { id: 'A', text: "A) Mobil Synergy, Mobil Synergy Superme+" },
            { id: 'B', text: "B) Mobil Synergy Extra, Mobil Synergy Superme+" },
            { id: 'C', text: "C) Mobil Synergy Extra, Mobil Synergy" },
            // { id: 'D', text: "D) 7 años" },
          ],
        },
        {
          pregunta2: "¿Cuántos productos para motos tiene la familia Mobil Super Moto?",
          respuesta2: "",
          options: [
            { id: 'A', text: "A) 2" },
            { id: 'B', text: "B) 4" },
            { id: 'C', text: "C) 5" },
            // { id: 'D', text: "D) Mobil Moto Super" },
          ],
        },
      ],
      showQuestions: false,
    };
  },
  methods: {
    // fetchData() {
    //   axios
    //     .get('https://www.mobilpredictor.com.mx/datos/api/questionsMobil')
    //     .then((response) => {
    //       console.log(response.data);
    //       Filtra las preguntas que están activas
    //                 this.questions = response.data.filter((question) => {
    //         const currentDate = moment().tz("America/Mexico_City");
    //         const startDate = moment.utc(question.start_date).tz("America/Mexico_City");
    //         const expirationDate = moment.utc(question.expiration_date).tz("America/Mexico_City");

    //         return currentDate.isSameOrAfter(startDate) && currentDate.isSameOrBefore(expirationDate) && document.cookie.indexOf('respuestasMobil=mobil') === -1;
    //       });

    //         this.questions = response.data.filter((question) => {
    //         return (question.id === 1 || question.id === 2) && document.cookie.indexOf('respuestasMobil=mobil') === -1;
    //         });

    //       Inicializa `form` con un objeto para cada pregunta
    //       this.form = this.questions.map(() => ({
    //         answer: "",
    //         ... otras propiedades necesarias
    //       }));

    //       console.log(this.questions);

    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    submitFormQuestionsMobil() {
      const predictor = JSON.parse(localStorage.getItem('predictor'));
      const email = predictor.email;
      // Recorre cada respuesta en `form`
      this.form.forEach((item, index) => {
        const data = {
          email: email,
          question: this.form[index][`pregunta${index + 1}`],
          answers: [item[`respuesta${index + 1}`]],
          pilot: "mobil",
          question_type: "mobil",
          is_correct: "",
        };

        // Envía los datos a la API
        axios
          .post('https://www.mobilpredictor.com.mx/datos/api/questionsMobil', data)
          .then((response) => {
            // console.log(response);
            // Maneja la respuesta de la API
            if (index === this.form.length - 1) {
              Swal.fire({
                title: "¡Hasta la próxima Gran carrera!",
                text: "Guardamos tus respuestas, gracias por participar",
                icon: 'success',
                background: "#000",
                color: "#fff",
                customClass: {
                  content: 'sweetAlertFont'
                },
              });

              document.cookie = "respuestasMobil=mobil; expires=" + new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toUTCString();
              setTimeout(() => {
                location.reload();
              }, 3000);
            }
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "¡Espera!",
              text: "Envíanos tus respuestas completas para poder participar en el predictor y conseguir más puntos.",
              icon: 'info',
              confirmButtonText: 'Volver a intentar',
              background: "#000",
                color: "#fff",
                customClass: {
                  content: 'sweetAlertFont'
                },
              });
          });
      });
    },
    alertaCambioPaginaPilotos() {
      if (document.cookie.indexOf('respuestasMobil=mobil') === -1) {
      Swal.fire({
      title: "¡Espera!",
      text: "Estás a punto de cambiar de página, envía tus respuestas antes de irte",
      icon: "info",
      confirmButtonText: 'Ok',
      background: "#000",
      color: "#fff",
      customClass: {
      content: 'sweetAlertFont'
      },
      });
      } else {
      this.$router.push("/predicciones-pilotos-checo");
      }
    },
  },
  computed: {
    isFormVisible() {
      return document.cookie.indexOf('respuestasMobil=mobil') === -1;
    }
  },
  // created() {
  //   this.fetchData();
  // },
  mounted() {
    this.fetchData();
    if (!localStorage.getItem('predictor')) {
      this.$router.push('/iniciar-sesion');
    }
  },
};
</script>
